import request from "@/utils/request";

export function queryOrgDict() {
  return request({
    url: "/sys/orgInfo/queryOrgDict",
    method: "get",
  });
}

export function queryOrg(page, size, data) {
  return request({
    url: "/sys/orgInfo/queryOrg?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addOrg(data) {
  return request({
    url: "/sys/orgInfo/addOrg",
    method: "post",
    data,
  });
}

export function getOrg(data) {
  return request({
    url: "/sys/orgInfo/getOrg/" + data,
    method: "get",
  });
}

export function updateOrg(data) {
  return request({
    url: "/sys/orgInfo/updateOrg",
    method: "post",
    data,
  });
}

export function deleteOrg(data) {
  return request({
    url: "/sys/orgInfo/deleteOrg",
    method: "post",
    data,
  });
}

export function queryOrgDictByOrgType(data) {
  return request({
    url: "/sys/orgInfo/queryOrgDictByOrgType/" + data,
    method: "get",
  });
}
