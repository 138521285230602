<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div
        style="
          display: flex;
          flex-direction: column;
          height: calc(100vh - 180px);
        "
      >
        <div style="display: flex; flex-wrap: wrap">
          <div style="display: flex" class="condition-item">
            <label class="fn-14">用户名</label>
            <el-input
              v-model="searchAccount"
              placeholder="请输入用户名"
              style="width: 202px; margin-right: 20px"
              clearable
              size="small"
              autocomplete="off"
            ></el-input>
            <el-button
              v-if="search"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              size="small"
              >查询</el-button
            >
            <el-button
              v-if="search"
              type="info"
              icon="el-icon-refresh"
              @click="resetSearch"
              size="small"
              >重置</el-button
            >
          </div>
          <div class="condition-item">
            <el-button
              v-if="addButton"
              type="success"
              icon="el-icon-plus"
              @click="handleAdd"
              size="small"
              >新增</el-button
            >
            <el-button
              v-if="resetPassword"
              type="primary"
              icon="el-icon-refresh"
              @click="handleResetPassword"
              size="small"
              >重置密码</el-button
            >
            <el-button
              v-if="disable"
              type="warning"
              icon="el-icon-video-play"
              @click="handleDisable"
              size="small"
              >禁用</el-button
            >
            <el-button
              v-if="enable"
              type="success"
              icon="el-icon-video-pause"
              size="small"
              @click="handleEnable"
              >启用</el-button
            >
            <el-button
              v-if="deleteButton"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete"
              size="small"
              >删除</el-button
            >
          </div>
        </div>

        <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
          <el-table
            :data="userList"
            v-loading="tableLoading"
            stripe
            border
            height="100%"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              fixed
              type="selection"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column fixed prop="account" label="用户名" width="200">
            </el-table-column>
            <!-- <el-table-column prop="tell" label="电话" width="150" align="center">
            </el-table-column> -->
            <el-table-column prop="name" label="真实姓名" width="120" align="center">
              <template slot-scope="scope">
            <span>{{ scope.row.name}}</span></template
          >
            </el-table-column>
            <el-table-column prop="tell" label="联系电话" width="150" align="center">
              <template slot-scope="scope">
            <span>{{ scope.row.tell }}</span></template
          >
            </el-table-column>
            <!--        <el-table-column prop="orgName" label="机构名称" width="200">-->
            <!--        </el-table-column>-->
            <!--        <el-table-column prop="deptName" label="部门名称" width="200">-->
            <!--        </el-table-column>-->
            <el-table-column
              prop="isDisabled"
              label="是否禁用"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.isDisabled === 0"
                  type="success"
                  size="small"
                  >启用</el-tag
                >
                <el-tag v-else type="danger" size="small">禁用</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="240" align="center">
              <template slot-scope="scope">
                <div
                  v-if="view"
                  class="my-button"
                  @click="handleView(scope.row.account)"
                >
                  查看
                </div>
                <div
                  v-if="updateButton"
                  class="my-button-green"
                  @click="handleUpdate(scope.row.account)"
                >
                  修改
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div style="display: flex; justify-content: right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>

        <el-dialog
          :title="
            dialogType === 1
              ? '新增用户'
              : dialogType === 2
              ? '查看用户'
              : '修改用户'
          "
          :visible.sync="dialogVisible"
          width="600px"
        >
          <el-form
            ref="userForm"
            :model="userForm"
            :rules="userRules"
            label-width="80px"
          >
            <el-form-item label="账号" prop="account">
              <el-input
                type="text"
                v-model="userForm.account"
                placeholder="请输入账号"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="tell">
              <el-input
                type="text"
                v-model="userForm.tell"
                placeholder="请输入电话"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="真实姓名" prop="name">
              <el-input
                type="text"
                v-model="userForm.name"
                placeholder="请输入真实姓名"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>

            <el-form-item label="所属学校" prop="schoolId">
              <el-select
                style="width: 100%"
                v-model="userForm.schoolId"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请输入学校名或代码检索"
                :disabled="dialogType === 2"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in schools"
                  :key="item.id"
                  :label="item.schoolFilterName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!--        <el-form-item label="机构" prop="orgId">-->
            <!--          <el-select-->
            <!--            v-model="userForm.orgId"-->
            <!--            placeholder="请选择机构"-->
            <!--            @change="handleOrgChange"-->
            <!--            :disabled="dialogType === 2"-->
            <!--          >-->
            <!--            <el-option-->
            <!--              v-for="item in orgOptions"-->
            <!--              :key="item.id"-->
            <!--              :label="item.orgName"-->
            <!--              :value="item.id"-->
            <!--            >-->
            <!--            </el-option>-->
            <!--          </el-select>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="部门" prop="deptId">-->
            <!--          <el-select-->
            <!--            v-model="userForm.deptId"-->
            <!--            placeholder="请选择部门"-->
            <!--            :disabled="dialogType === 2"-->
            <!--          >-->
            <!--            <el-option-->
            <!--              v-for="item in deptOptions"-->
            <!--              :key="item.id"-->
            <!--              :label="item.name"-->
            <!--              :value="item.id"-->
            <!--            >-->
            <!--            </el-option>-->
            <!--          </el-select>-->
            <!--        </el-form-item>-->
            <el-form-item label="角色" prop="roles">
              <el-select
                v-model="userForm.roles"
                multiple
                placeholder="请选择角色"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
                <el-option
                  v-for="item in roleOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="是否禁用"
              prop="isDisabled"
              style="margin-bottom: 0px"
            >
              <el-switch
                v-model="userForm.isDisabled"
                :active-value="1"
                :inactive-value="0"
                :disabled="dialogType === 2"
              ></el-switch>
            </el-form-item>
            <el-form-item label="性别" prop="sex" style="margin-bottom: 10px">
              <el-radio-group
                v-model="userForm.sex"
                :disabled="dialogType === 2"
              >
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <el-date-picker
                v-model="userForm.birthday"
                type="date"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择生日"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input
                type="text"
                v-model="userForm.email"
                placeholder="请输入邮箱"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                v-model="userForm.remark"
                placeholder="请输入备注"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              v-if="dialogType !== 2"
              type="primary"
              @click="handleConfirm"
              :loading="confirmLoading"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  queryUser,
  addUser,
  getUser,
  updateUser,
  resetPassword,
  disableUser,
  enableUser,
  deleteUser,
} from "@/api/user";
import { queryOrgDict } from "@/api/org";
import { queryDeptDict } from "@/api/dept";
import { getSchoolFiller } from "@/api/school";
import { queryRoleDict } from "@/api/role";
import { queryPageButton } from "@/api/permission";
import { formatPh,formatName } from "@/utils/validate";

export default {
  name: "UserMgt",
  data() {
    var res = /^[0-9a-zA-Z_]{1,}$/;
    var tells = /^[1]([3-9])[0-9]{9}$/;
    const validateAccount = (rule, value, callback) => {
      if (!value) {
        callback(new Error("账号不能为空"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确，只能输入字母、数字、下划线"));
        } else {
          callback();
        }
      }
    };
    const validateOrgId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("机构不能为空"));
      } else {
        callback();
      }
    };
    const validateDeptId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("部门不能为空"));
      } else {
        callback();
      }
    };
    const validateRoles = (rule, value, callback) => {
      if (!value || value.length === 0) {
        callback(new Error("角色不能为空"));
      } else {
        callback();
      }
    };
    const validateIsDisabled = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("是否禁用不能为空"));
      } else {
        callback();
      }
    };
    const validateTell = (rule, value, callback) => {
      if (!value) {
        callback(new Error("电话不能为空"));
      } else {
        if (tells.test(value) == false) {
          callback(new Error("请输入正确的11位数手机号码"));
        } else {
          callback();
        }
      }
    };
    return {
      searchAccount: "",
      searchOrgId: "",
      userList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogType: 1,
      dialogVisible: false,
      userForm: {},
      userRules: {
        account: [
          { required: true, trigger: "blur", validator: validateAccount },
        ],
        // orgId: [{ required: true, trigger: "blur", validator: validateOrgId }],
        // deptId: [
        //   { required: true, trigger: "blur", validator: validateDeptId },
        // ],
        roles: [{ required: true, trigger: "blur", validator: validateRoles }],
        isDisabled: [
          { required: true, trigger: "blur", validator: validateIsDisabled },
        ],
        tell: [{ required: true, trigger: "blur", validator: validateTell }],
      },
      orgOptions: [],
      deptOptions: [],
      roleOptions: [],
      confirmLoading: false,

      //学校下拉
      schools: [],
      value: [],
      allSchools: [],
      loading: false,
      search: false,
      addButton: false,
      resetPassword: false,
      disable: false,
      enable: false,
      deleteButton: false,
      view: false,
      updateButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
    // this.queryOrg();
  },
  methods: {
    queryButton() {
      const data = 14;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.search = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "resetPassword") {
            this.resetPassword = true;
          }
          if (button.code === "disable") {
            this.disable = true;
          }
          if (button.code === "enable") {
            this.enable = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.view = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableLoading = true;
      const data = {
        account: this.searchAccount,
        // orgId: this.searchOrgId,
      };

      queryUser(this.currentPage, this.pageSize, data).then((resp) => {
        this.userList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    // queryOrg() {
    //   queryOrgDict().then((resp) => {
    //     this.orgOptions = resp.data;
    //   });
    // },
    // queryDept() {
    //   const data = this.userForm.orgId;
    //
    //   queryDeptDict(data).then((resp) => {
    //     this.deptOptions = resp.data;
    //   });
    // },
    queryRole() {
      queryRoleDict().then((resp) => {
        this.roleOptions = resp.data;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchAccount = "";
      this.currentPage = 1;
      this.query();
    },
    handleAdd() {
      this.querySchools();
      this.queryRole();
      this.userForm = {};
      if (this.$refs.userForm !== undefined) {
        this.$refs.userForm.resetFields();
      }
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    // handleOrgChange() {
    //   this.queryDept();
    //   if (this.userForm.deptId) {
    //     this.userForm.deptId = "";
    //   }
    // },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    add() {
      if (!this.userForm.isDisabled) {
        this.userForm.isDisabled = 0;
      }
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.userForm;
          const roles = [];
          for (const item of data.roles) {
            roles.push({
              id: item,
            });
          }
          data.roles = roles;

          addUser(data)
            .then((resp) => {
              this.$notify({
                title: "新增成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleResetPassword() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的用户不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.account);
      }

      resetPassword(data)
        .then((resp) => {
          this.$notify({
            title: "重置成功",
            message: resp.message,
            type: "success",
          });
          this.currentPage = 1;
          this.query();
        })
        .catch((error) => {});
    },
    handleDisable() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的用户不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.account);
      }

      disableUser(data)
        .then((resp) => {
          this.$notify({
            title: "禁用成功",
            message: resp.message,
            type: "success",
          });
          this.currentPage = 1;
          this.query();
        })
        .catch((error) => {});
    },
    handleEnable() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的用户不能为空",
          type: "warning",
        });
        return;
      }

      const data = [];
      for (const item of this.tableSelection) {
        data.push(item.account);
      }

      enableUser(data)
        .then((resp) => {
          this.$notify({
            title: "启用成功",
            message: resp.message,
            type: "success",
          });
          this.currentPage = 1;
          this.query();
        })
        .catch((error) => {});
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的用户不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.account);
          }

          deleteUser(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleView(account) {
      this.querySchools();
      const data = account;
      getUser(data)
        .then((resp) => {
          if (this.$refs.userForm !== undefined) {
            this.$refs.userForm.resetFields();
          }
          this.userForm = resp.data;
          const roles = [];
          for (const item of this.userForm.roles) {
            roles.push(item.id);
          }
          this.userForm.roles = roles;

          this.queryRole();
          // this.queryDept();
          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdate(account) {
      this.querySchools();
      const data = account;
      getUser(data)
        .then((resp) => {
          if (this.$refs.userForm !== undefined) {
            this.$refs.userForm.resetFields();
          }
          this.userForm = resp.data;
          const roles = [];
          for (const item of this.userForm.roles) {
            roles.push(item.id);
          }
          this.userForm.roles = roles;

          this.queryRole();
          // this.queryDept();
          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    update() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.userForm;
          const roles = [];
          for (const item of data.roles) {
            roles.push({
              id: item,
            });
          }
          data.roles = roles;

          updateUser(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
      // 姓名、手机号脱敏
      execDecrypt(str,mod){
      if (!str) {
          return str;
        } else if (mod == "ph") {
          return formatPh(str);
        } else if (mod == "name") {
          return formatName(str);
        } 
    }
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
