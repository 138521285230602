import request from "@/utils/request";

export function queryDeptDict(data) {
  return request({
    url: "/sys/deptInfo/queryDeptDict/" + data,
    method: "get",
  });
}

export function queryDept(page, size, data) {
  return request({
    url: "/sys/deptInfo/queryDept?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addDept(data) {
  return request({
    url: "/sys/deptInfo/addDept",
    method: "post",
    data,
  });
}

export function getDept(data) {
  return request({
    url: "/sys/deptInfo/getDept/" + data,
    method: "get",
  });
}

export function updateDept(data) {
  return request({
    url: "/sys/deptInfo/updateDept",
    method: "post",
    data,
  });
}

export function deleteDept(data) {
  return request({
    url: "/sys/deptInfo/deleteDept",
    method: "post",
    data,
  });
}
